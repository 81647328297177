<script setup>
import { Link } from '@inertiajs/vue3';

const email = defineModel();
const subscribed = false;

</script>

<template>
    <footer class="footer p-10 bg-base-200 text-base-content">
        <nav>
            <h6 class="footer-title">For gym users</h6>
            <a :href="route('places.search')" class="link link-hover">Find a gym</a>
            <a :href="route('places.compare')" class="link link-hover">Compare gyms</a>
            <a :href="route('deals-and-promos')" class="link link-hover">Deals & promos</a>
            <a :href="route('places.show')" class="link link-hover">All gyms</a>
            <a :href="route('blog.index')" class="link link-hover">Blog</a>
        </nav>
        <nav v-if="$country === 'New Zealand'">
            <h6 class="footer-title">Regions</h6>
            <a :href="route('places.show', ['Auckland'])" class="link link-hover">Auckland</a>
            <a :href="route('places.show', ['Wellington'])" class="link link-hover">Wellington</a>
            <a :href="route('places.show', ['Christchurch'])" class="link link-hover">Canterbury</a>
            <a :href="route('places.show', ['Waikato'])" class="link link-hover">Waikato</a>
            <a :href="route('places.show', ['Bay of Plenty'])" class="link link-hover">Bay of Plenty</a>
        </nav>
        <nav v-else-if="$country === 'Australia'">
            <h6 class="footer-title">Regions</h6>
            <a :href="route('places.show', ['New South Wales'])" class="link link-hover">New south wales</a>
            <a :href="route('places.show', ['Victoria'])" class="link link-hover">Victoria</a>
            <a :href="route('places.show', ['Queensland'])" class="link link-hover">Queensland</a>
            <a :href="route('places.show', ['South Australia'])" class="link link-hover">South Australia</a>
            <a :href="route('places.show', ['Western Australia'])" class="link link-hover">Western Australia</a>
        </nav>
        <nav>
            <h6 class="footer-title">For gym owners</h6>
            <a :href="route('list-on-fitchoice')" class="link link-hover">List on FitChoice</a>
            <a :href="route('listing-plans')" class="link link-hover">Listing plans</a>
            <a :href="route('contact-us')" class="link link-hover">Request removal</a>
            <a :href="route('login')" class="link link-hover">Owner login</a>
        </nav>
        <nav>
            <h6 class="footer-title">About FitChoice</h6>
            <a :href="route('about-us')" class="link link-hover">About us</a>
            <a :href="route('contact-us')" class="link link-hover">Contact us</a>
            <a :href="route('terms.show')" class="link link-hover">Terms of use</a>
            <a :href="route('policy.show')" class="link link-hover">Privacy policy</a>
            <a :href="route('cookie-policy')" class="link link-hover">Cookie policy</a>
        </nav>
        <form>
            <h6 class="footer-title">Newsletter</h6>
            <fieldset class="form-control">
                <label class="label">
                    <span class="label-text">Keep up to date</span>
                </label>
                <div class="join">
                    <input type="email" placeholder="Enter your email" class="input input-bordered join-item w-52" v-model="email" />
                    <Link href="/newsletter/subscribe"
                          method="post"
                          as="button"
                          :data="{email: email}"
                          preserve-scroll
                          :onSuccess="() => {email = null; subscribed = true}"
                          class="btn btn-primary join-item">
                        Subscribe</Link>
                </div>
                <p class="mt-2 font-bold text-success" v-if="subscribed">Thank you for subscribing!</p>
            </fieldset>
        </form>
    </footer>
</template>



<style scoped>

</style>
